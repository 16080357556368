body{
    background: #040d20;
    font-family: 'Poppins', sans-serif;
}
/* Deals Box Container */
.deals_boxes_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* DiÃƒÆ’Ã†â€™ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã¢â‚¬Â¦Ãƒâ€šÃ‚Â¸er stillendirme buraya eklenebilir */
    flex-direction: column;
    width: 100%;
}

.deals_box{
    width: 100%;
    display: flex;
    margin-bottom: .75rem;
    padding: 0rem 1rem;
    position: relative;
    border-radius: 6px;
    transition: all .4ms ease-in-out;
    border-bottom: 1px solid #8294b14a;
    display: inline-flex;
}
.deals_box:hover{

    background: rgba(130, 148, 177, .05);
    transition: all .5s ease-in-out;
}
/* Logo */
.deals_box_logo img {
    max-width: 100px;
    height: auto;
}

/* Offer */
.deals_box_offer p {
    font-size: 16px;
    /* DiÃƒÆ’Ã†â€™ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã¢â‚¬Â¦Ãƒâ€šÃ‚Â¸er stillendirme buraya eklenebilir */
}
.deals_box_logo_offer{
    width:44%;
    display:flex;
}
.deals_box_logo{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(90.81deg, rgba(130, 148, 177, 0), rgba(130, 148, 177, .12) 50.52%, rgba(130, 148, 177, 0)), rgba(130, 148, 177, .02);
    border-radius: 14px;
    border: 1px solid rgba(130, 148, 177, .5);
    cursor: pointer;
    padding: 0;
    min-height: 167px;
}
.deals_box_offer p{
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    font-size: .9rem !important;
}
.deals_box_logo img{
    margin-bottom: 1rem;
}

/* Payment Methods */
.deals_box_offer{
    color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    padding: 0 1.5rem;
}
.deals_box_payment_methods {
    /* DiÃƒÆ’Ã†â€™ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã¢â‚¬Â¦Ãƒâ€šÃ‚Â¸er stillendirme buraya eklenebilir */
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 999999999;
}
.dbox_pmethods{
    display: flex;
    align-items: center;
    border-radius: 6px;
    flex-wrap: wrap;
    padding: .2rem .5rem;
    text-align: center;
    justify-content: center;
    border-color:#2ae7f380;
}
.dbox_pmethod{
    width: 14%;
    margin: .5rem .4rem;
}

.dbox_pmethod img {
    max-width: 32px;
    height: 23px;
    /* DiÃƒÆ’Ã†â€™ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã¢â‚¬Â¦Ãƒâ€šÃ‚Â¸er stillendirme buraya eklenebilir */
    object-fit: contain;
}

/* Rating */
.dbox_rating{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: .25rem;
}
.deals_box_rating {
    /* DiÃƒÆ’Ã†â€™ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã¢â‚¬Â¦Ãƒâ€šÃ‚Â¸er stillendirme buraya eklenebilir */
    position: static;
    bottom: -1rem;
    left: 1rem;
    width: 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dbox_star {
    /* YÃƒÆ’Ã†â€™ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã¢â‚¬Å¡Ãƒâ€šÃ‚Â±ldÃƒÆ’Ã†â€™ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã¢â‚¬Å¡Ãƒâ€šÃ‚Â±z iÃƒÆ’Ã†â€™Ãƒâ€ Ã¢â‚¬â„¢ÃƒÆ’Ã¢â‚¬Å¡Ãƒâ€šÃ‚Â§in stillendirme */
}

.dbox_star_icon {
    /* YÃƒÆ’Ã†â€™ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã¢â‚¬Å¡Ãƒâ€šÃ‚Â±ldÃƒÆ’Ã†â€™ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã¢â‚¬Å¡Ãƒâ€šÃ‚Â±z simgesi iÃƒÆ’Ã†â€™Ãƒâ€ Ã¢â‚¬â„¢ÃƒÆ’Ã¢â‚¬Å¡Ãƒâ€šÃ‚Â§in stillendirme */
    fill: var(--ctr-star-fill);
    display: block;
    height: 16px;
    width: 16px;
    filter: drop-shadow(0 0 10px rgba(239,180,0,.7));
}

.dbox_votes {
    /* Oylama bilgileri iÃƒÆ’Ã†â€™Ãƒâ€ Ã¢â‚¬â„¢ÃƒÆ’Ã¢â‚¬Å¡Ãƒâ€šÃ‚Â§in stillendirme */
    font-size: .8rem;
}

/* Button */
.deals_box_button {
    /* DiÃƒÆ’Ã†â€™ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÆ’Ã¢â‚¬Â¦Ãƒâ€šÃ‚Â¸er stillendirme buraya eklenebilir */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
    width: 16%;
}
.deals_box_button a{
    display: flex;
    font-size: .7rem;
    padding: 1rem .9rem;
    border: 1px solid #2c5e6b;
    transition: .6s all ease;
    text-align: center;
    background: linear-gradient(90deg, #69d271, #29aa61);
    box-shadow: 0 4px 10px rgba(13, 16, 21, .6);
}
.deals_box_button a:hover{
    box-shadow: 0 0 20px #1a949b;
}

.button {
    /* Buton genel stilleri */
}

.button--claim {
    /* Buton ÃƒÆ’Ã†â€™Ãƒâ€ Ã¢â‚¬â„¢ÃƒÆ’Ã¢â‚¬Å¡Ãƒâ€šÃ‚Â¶zel stilleri */
}
.deals_box_featureds{width: 100%;border-left: 1px solid #eeeeee2b;border-right: 1px solid #eeeeee2b;padding: 0 2rem;display: flex;align-items: center;}
.deals_box_featureds ul{
    list-style: none;
}
.deals_box_featureds ul li{
    font-size: .8rem;
    padding: .25rem 0;
}
.deals_box_featureds ul li i{
    color: #69eeab;
}
.deals_box_tag{
    padding: .2rem .15rem;
    font-size: .75rem;
    top: -1.5px;
    left: 28px;
    opacity: 1;
    border: none;
    border-radius: 7px !important;
    font-weight: 700;
    border-top-left-radius: 14px !important;
    border-bottom-right-radius: 14px !important;
    background: #310e77;
    border-top-right-radius: 0px !important;
    border-bottom-left-radius: 0 !important;
    padding-left: 1.1rem;
    height: 35px;
    padding-right: .8rem !important;
    display: flex;
    align-items: center;
    position: absolute;
}
.deal_box_number{
    position: absolute;
    left: -1.5px;
    top: -1.5px;
    width: 42px;
    font-size: .8rem;
    font-weight: 700;
    height: auto;
    text-align: center;
    z-index: 99;
    background: white;
    border: none;
    border-radius: 100%;
    background: #4343da;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 14px !important;
    border-bottom-right-radius: 14px !important;
    border-top-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}
.section{position:relative;z-index: 9;padding-top: 0rem;}

@media(max-width:768px){
    .deals_box_offer{
        width: 100%;
        z-index: 999999;
        position: relative;
        padding-top: 1rem;
    }
    .deals_box_offer p{font-size: .7rem !important;}
    .deals_box_logo{
        width: 100%;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    div.deals_box div.deals_box_logo .dbox_pmethods img{
        width: 100%;
        object-fit: contain;
        background-attachment: fixed;
        height: 23px !important;
        max-height: 23px !important;
        min-height: 23px !important;

    }
    .deals_box{
        flex-direction: column;
        padding-bottom: 1rem;
    }
    .deals_box_featureds ul li i{margin-right:.2rem}
    .deals_box_featureds ul{
        display: flex;
        align-items: center;
        width: 100%;
    }
    .deals_box_featureds ul li{
        font-size: .6rem;
        padding: 0 .2rem;
        margin: auto;
        display: flex;
        align-items: center;
    }
    .deals_box_button{width:100%;padding-bottom: 0;padding-top: .8rem;}
    .deals_box_button a{width:80%;text-align:center;justify-content:center}
    .deals_box_featureds{
        width: 100%;
        padding: 0;
        margin-top: .8rem;
        border: none;
    }
    .dbox_pmethods{
        flex-wrap: nowrap;
        overflow-x: scroll;
        padding: 0;
        display: flex;
        min-width: 100%;
        justify-content: flex-start;
    }
    .dbox_pmethod{
        width: 2rem !important;
        margin: 0 !important;
        float: left !important;
        display: flex;
        /* flex: 0 0 auto; */
        height: 2rem !important;
        min-width: 2.5rem !important;
        justify-content: center !important;
    }

}


/* Snow Effect - Start */
.snowEffect { width:100%; height:100%; position:absolute; top:0; left:0; z-index:800; }
/* Snow Effect - End*/

/* Ice Wrap - Start */
.iceWrap {width:100%;height:71px;background:url('../img/ice.png') repeat-x bottom left;position:absolute;top: 60px;left:0;z-index:9000;}
/* Ice Wrap - End */


.top-light
{
    top: 100px;
    left:0;
    width:100%;
    height:100%;
    content:'';
    z-index:100;
    display:block;
    position:absolute;
    max-width:100vw;
    max-height:100vh;
    background-image:url('../img/light.png');
    background-position:top center;
    background-repeat:no-repeat;
    background-size:contain;
    overflow:hidden;
    opacity: .7;
}

canvas{
    z-index: 5;
    position: absolute;
    top: 0;
}
.main{
    position: relative;
    z-index: 999;
    padding-top: 40px;
    transform: scale(0.9);
    transform-origin: 50% 0px;
}
.section--hero{
    position: relative;
    z-index: 999;
}
.section--hero:before{
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url(../img/Bgpic.png?t=1) no-repeat 50% 50% / cover;
    width: 100%;
    height: auto;
    z-index: -1;
    margin-top: 50px;
}
.section--table .container{position: relative;z-index: 999;}
.deals_box_link{
    position: absolute;
    z-index: 99999;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
}


.table_row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: justify!important;
    justify-content: flex-start;
    -ms-flex-align: center;
    align-items: center;
    text-align: center
}

@media (max-width: 768px) {
    .table_row {
        -ms-flex-wrap:wrap;
        flex-wrap: wrap;
        padding: 10px 0;
        margin: 15px 10px;
        margin-bottom: 5px;
        margin-top: 0
    }
}

@media (max-width: 374px) {
    .table_row {
        padding:12px 5px 15px;
        margin-bottom: 5px
    }
}

.table_row_title {
    background: rgba(130,148,177,.07);
    padding: 3px 0;
    width: 100%;
    padding-left: 1rem;
    justify-content: flex-start;
    display: flex;
    align-items: flex-start !important;

}

@media (max-width: 768px) {
    .table_row_title {
        display:none
    }
}

.table_row_title p {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    font-weight: 400;
    color: rgba(130,148,177,.8)
}

.table_row_title .table_title {
    padding: 0;
    /* flex: 0 0 auto; */
    /* display: flex; */
}

.table_col-number {
    width: 5%
}

@media (max-width: 992px) {
    .table_col-number {
        display:none
    }
}

.table_col-site {
    width: 25%;
    padding: 15px
}

@media (max-width: 992px) {
    .table_col-site {
        width:25%
    }
}

@media (max-width: 768px) {
    .table_col-site {
        width:100%
    }
}
.subscription_form label{
    color: #cbd8fc;
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: .5rem;
}
.subscription_form{display: flex;margin-top: .5rem;z-index: 9999999;position: relative;width: 60%;margin-bottom: 1rem;flex-direction: column;}
.subscription_form .form-group{
    width: 50%;
}
.subscription_form .form-group input{
    height: 100%;
    border-radius: 7px;
    padding-left: 1rem;
    font-size: .9rem;
    width: 100%;
    border: none;
    outline: none;
}
.subscription_form .form-group button{
    background: transparent;
    font-weight: 400;
    font-size: .9rem;
    height: 50px;
    background: #89a0e7;
    border: none;
    margin-left: .5rem;
    position: relative;
}

header{
    background: rgba(4, 13, 32, .7);
    -webkit-backdrop-filter: blur(14px);
    backdrop-filter: blur(14px);
    z-index: 99999;
    position: fixed;
    left: 0;
    right: 0;
    padding: 0;
    border-bottom: 1px solid #8294b145;
    display: flex;
    align-items: center;
    height: 40px;
    transform: scale(0.9);
    transform-origin: 50% 0px;
}
header .wrapper{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
}
.header_logo{
    font-size: 1.1rem;
}
.header_menu{}
.header_menu ul {
    display: flex;
    list-style: none;
}
.header_menu ul li {}
.header_menu ul li a {
    text-decoration: none;
    color: white;
    font-weight: 400;
    padding: 0 1rem;
    font-size: .95rem;
    display: none;
}
.header_logo span{
    color: #88a0e7;
    font-weight: 550;
}

.subscription_wrapper{
    display: flex;
}
.faq_title{
    font-size: 2rem;
    font-weight: 550;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}

.content-block{
    padding: 30px;
    max-width: 840px;
    margin: 0 auto;
    text-align: left;
    border: 1px solid rgba(130, 148, 177, .5);
    background: linear-gradient(90.81deg, rgba(130, 148, 177, 0), rgba(130, 148, 177, .12) 50.52%, rgba(130, 148, 177, 0)), rgba(130, 148, 177, .02);
    border-radius: 14px;
    margin-bottom: 30px;
}
.dbox_rating_number{

}
.dbox_rating_number span{font-weight: 600;font-size: 2.5rem;}
.table_col-rating{width: 15%;}
.table_col-score{width: 20%;}
.table_col-btn{
    width: 16%;
}
.table_col-bonus{
    min-width:19%;
}
.dbox_pmethod_dropdown{
    position: relative;

}
.dbox_pmethod_dropdown .dropdown_content{
    position: absolute;
    top: 100%;
    right: 0;
    background: #0d1521;
    border-radius: 6px;
    padding: 10px;
    display: none;
    z-index: 999999999;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    width: max-content; /* İçeriğe göre genişlik */
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3'lü grid */
    gap: 10px;
    min-width: 150px;
}
.dropdown_button_payment{
    display: flex;
    background: #13265b;
    border: none;
    color: white;
    border-radius: 3px;
    font-size: .8rem;
    padding: .5rem 1rem;
}
.dbox_pmethod_dropdown .dropdown_content .dbox_pmethod{
    width: 50%;
    margin: 0;
    padding: .5rem .5rem;
}

.deals_box_button_mobile{
    display: none;
}
.deals_box_payment_methods_mobile{
    display: none;
}
@media(max-width:768px){
    .deals_box_payment_methods{
        width:100%;
    }
    .deals_box .deals_box_offer p span,.deals_box .deals_box_offer p span strong{font-weight:600 !important}
    div.deals_box .deals_box_offer p{
        flex-direction: column !important;
        margin-bottom: .5rem !important;
    }
    .section--hero{
        padding-top: 0 !important;
    }
    .subscription_form{width: 100%;padding-left: 1rem;margin-bottom: 1rem;margin-top:.5rem}
    .deals_box_logo{

    }
    .deals_box:first-child{
        border-color: #e8ab1a;
    }
    .deals_box:first-child .deals_box_logo_offer{
        border-color: #e8ab1a;
        /* border: none; */
    }
    .mp-lp-template-2 .casino-table__rows{
        padding-top:0;
    }
    .deals_box:first-child .deals_box_offer{
        border-color: #e8ab1a;
    }
    .dbox_pmethod_dropdown .dropdown_content .dbox_pmethod{
        width:100%;
        padding: 0 .5rem !important;
    }
    .dbox_pmethod_dropdown{
        display: flex;
        /* flex: 0 0 auto; */
    }
    .dbox_pmethod_dropdown .dropdown_content{
        display: flex !important;
        position: static !important;
        flex-direction: row !important;
        background: transparent;
        flex-wrap: nowrap;
        padding: 0;
    }
    .dropdown_button_payment{
        display: none;
    }
    .deals_box_logo{
        width:50% !important;
        border: none;
        margin-top: 0 !important;
        flex-direction: column !important;
        justify-content: center;
        align-items: center;
        padding-bottom: 0;
        padding-top: 1rem;
    }
    .deals_box_offer {
        width: 50% !important;
        z-index: 999999;
        position: relative;
        border: none;
        border-left: 1px solid  rgb(130 148 177 / 98%);
        padding: 0 .2rem;
        padding-top: .5rem !important;
    }
    .main{
        padding-top:0px !important;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .deals_box_tag{
        padding: .2rem .15rem;
        font-size: .75rem;
        top: -1.5px;
        left: 28px;
        opacity: 1;
        border: none;
        border-radius: 7px !important;
        font-weight: 700;
        border-top-left-radius: 14px !important;
        border-bottom-right-radius: 14px !important;
        background: #310e77;
        border-top-right-radius: 0px !important;
        border-bottom-left-radius: 0 !important;
        padding-left: 1.1rem;
        height: 25px;
        padding-right: .8rem !important;
        display: flex;
        align-items: center;
    }
    .section--table {
        padding-top: 0 !important;
    }
    .header_logo{
        padding-left:.5rem;
        font-size: .9rem !important;
    }
    header .wrapper{width:100% !important}
    .deals_box{flex-direction: row !important;padding-bottom: .5rem !important;flex-wrap: wrap !important;border-radius: 14px;border: 1px solid rgba(130, 148, 177, .35);}
    .deals_box_button{
        display: none;
    }
    .deals_box_button_mobile {
        display: flex;
    }
    #subscribe_form{
        width: 100% !important;
    }
    .deals_box_button_mobile a{
        display: flex;
        font-size: .7rem;
        margin-top: 0;
        margin-bottom: 15px;
        padding: .5rem .9rem;
        transition: all .4s;
        background: linear-gradient(90deg, #69d271, #29aa61);
        box-shadow: 0 4px 10px rgba(13, 16, 21, .6);
        border-radius: 100px;
        letter-spacing: .01em;
    }

    .deals_box_payment_methods{
        display: none;
    }
    .deals_box_rating_mobile{
        display: flex !important;
        flex-direction: row-reverse !important;
        margin: .5rem 0;
    }
    .deals_box_rating_mobile .dbox_votes{
        display: none;
    }
    .deals_box_payment_methods_mobile{
        display: flex;
        /* height: 60px; */
    }
    .deals_box_rating_mobile .dbox_rating_number span{
        font-size: .9rem !important;
        margin-left: .5rem;
    }
    .deals_box_logo_offer {
        border: 1px solid rgb(130 148 177 / 98%);
        border-top-left-radius: 0;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        border-top-right-radius: 12px;
        display: flex;
        width: 100%;
        background: linear-gradient(90.81deg, rgba(130, 148, 177, 0), rgba(130, 148, 177, .12) 50.52%, rgba(130, 148, 177, 0)), rgba(130, 148, 177, .02);
    }
    .deals_box_featureds{
        display:none;
    }
    .deals_box_featureds_mobile{
        display: flex !important;
        margin-top: .5rem !important;
    }
}

.deals_box_featureds_mobile{
    display: none;
}
#subscribe_form{
    display: flex;
    width: 70%;
}
.follow_box{
    display: inline-flex;
    align-items: center;
    background: #31447f;
    padding: .5rem 1rem;
    border-radius: 13px;
    font-size: .85rem;
    margin-top: 1rem;
}
.follow_box a{
    display: flex;color:white;text-decoration: none;
    align-items: center;
}
.follow_box svg{
    width: 2rem;
    height: 2rem;
    margin-right: .5rem;
}
.deals_box_rating_mobile{
    display: none;
}
.table_row_title{
    display: none;
}

@media(max-width: 768px){
    .subscription_form .form-group button{
        height:40px
    }

    #subscribe_form{
        margin:0 !important;
    }
    header{
        height:auto !important;
        padding: .25rem 0 !important;
        position: static !important;
    }
    .deals_boxes_wrapper .deals_box .deals_box_logo img{
        width: 100%;
        object-fit: contain;
        background-attachment: fixed;
        margin-bottom: .5rem !important;
        margin-top: 0rem !important;
        height: auto;
        min-height: 60px !important;
        min-width: 70%;
        max-height: 70px;
    }
    .deals_boxes_wrapper .deals_box_logo{
        justify-content: center !important;
        max-height: 120px;
        align-items: center;
        margin: auto !important;
        background: none !important;
    }

    .dbox_star_half{
        background: #dcdce6;
        position: relative;
    }
    .deal_box_number{
        height:25px !important;
    }
}



.dbox_star {
    background: #00b67a;
    margin: 0 1px;
    position: relative;
}
.dbox_star_half:before{
    content: '';
    background: #00b67a;
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    z-index:999;
    overflow: hidden;
}

.deals_box:nth-child(3n+1) .deals_box_tag {
    background: #3a1485;
}

.deals_box:nth-child(3n+2) .deals_box_tag{
    background: #ff5c5c;
}

.deals_box:nth-child(3n) .deals_box_tag{
    background: #5c5cff;
}


.deals_box:nth-child(3n+1) .deal_box_number_tag {
    background: #30126d !important;
}

.deals_box:nth-child(3n+2) .deal_box_number_tag{
    background: #eb4848 !important;
}

.deals_box:nth-child(3n) .deal_box_number_tag{
    background: #4343c1 !important;
}

@media (max-width: 768px) {
    .deals_box_rating {
        display: none;
    }
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}